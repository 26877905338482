import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    label: {
        display: "block",
        margin: "0",
        fontFamily: 'Nunito, sans-serif',
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: 1.8,
        color: "#6c757d"
    },
    input: {
        display: "block",
        WebkitBoxSizing: "border-box",
        boxSizing: "border-box",
        width: "100%",
        margin: "0 0",
        padding: "10px",
        border: "1px solid #ced4da",
        borderRadius: "4px",
        backgroundColor: "#fff",
        WebkitBoxShadow: "none",
        boxShadow: "none",
        fontFamily: 'Nunito, sans-serif',
        fontSize: "14px",
        fontWeight: "normal",
        lineHeight: 1.5,
        color: "#000",
        WebkitTransition: "border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s",
        transition: [
            "border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s",
            "box-shadow 0.5s, border-color 0.25s ease-in-out",
            "box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s"
        ],
        WebkitAppearance: "none",
        MozAppearance: "none",
        appearance: "none",
        "&:disabled": {
            backgroundColor: "#e6e6e6",
            cursor: "not-allowed"
        },
        "&:focus": {
            outline: "none",
            border: "1px solid #6c757d",
            backgroundColor: "#fff",
            WebkitBoxShadow: "none",
            boxShadow: "none",
            WebkitTransition: "border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s",
            transition: [
                "border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s",
                "box-shadow 0.5s, border-color 0.25s ease-in-out",
                "box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s"
            ]
        }
    },
    error: {
        lineHeight: '19px',
        "& label, & p": {
            color: '#cc4b37!important'
        },
        "& textarea": {
            color: '#cc4b37!important',
            backgroundColor: '#faedeb',
            borderColor: '#cc4b37!important'
        },
        "& span": {
            color: '#cc4b37!important',
            fontSize: '12px'
        },
    },
    helper: {
        color: "#6c757d",
        fontSize: '.75rem',
        lineHeight: '1.15rem',
        margin: '5px 0 0 0'
    }
}));

export default function MbTextArea({ error, helper, label, register, ...rest }) {
    const classes = useStyles();

    return (
        <div className={!!error ? classes.error : classes.main}>
            <label className={classes.label}>{label}</label>
            <textarea
                className={classes.input}
                type="text"
                {...register}
                {...rest}
            />
            {!!helper && <p className={classes.helper}>{helper}</p>}
            {!!error && <span>{error}</span>}
        </div>
    );
}