import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
    header: {
        alignItems: 'center',
        color: 'white',
        display: 'flex',
    }
}));

export default function Header({ Icon, title, subtitle }) {
    const classes = useStyles();

    return (
        <Grid container className={classes.header}>
            <Grid item flexDirection='row' display='flex' alignItems='center'>
                {Icon &&
                    <Icon fontSize="small" sx={{ fontSize: 24, mx: 2 }} />
                }
                <Box>
                    <Typography component="h1" variant="h5" fontWeight='bold' sx={{ color: '#323a46', fontSize: '20px', lineHeight: '75px' }}>
                        {title}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
}